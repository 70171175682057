<!--
 * @Author: yuwenwen
 * @Date: 2022-11-29 14:09:37
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-12-05 17:23:47
 * @FilePath: \nc-funeral-mobile\src\views\obituary\obituaryList.vue
-->
<template>
  <div class="obituary-list-page">
     <div v-for="(item,index) in tempList" :key="index" class="temp-item" @click="toTemplateDetails(item)">
        <img :src="item.image" />
        <div class="name-text">{{item.name}}</div>
     </div>
  </div>
</template>
<script>
import {getObituaryTempList} from '@/api/obituary'
import {getImagesUrl} from '@/utils/funeral.js'
export default {
  name: "ObituaryList",
  data() {
    return {
      tempList: [],
    };
  },
  created() {
    this.getTempList();
  },
  methods: {
    // 获取模板列表
    async getTempList() {
        let [err,res] = await this.$awaitWrap(getObituaryTempList());
        if(err){
            return;
        }
        this.tempList = res.data.records.map((item)=>{
          item.image = getImagesUrl(item.image);
          return item;
        });
        console.log(res);
    },
    // 页面跳转
    toTemplateDetails(item){
      this.$store.dispatch('setObituaryContent',{});
        this.$router.push({path:item.path,query:{templateId:item.id,templatePath:item.path}});
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/obituary.less";
</style>